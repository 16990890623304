import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectHeading = makeShortcode("ProjectHeading");
const ProjectSection = makeShortcode("ProjectSection");
const ProjectInfo = makeShortcode("ProjectInfo");
const ButtonLink = makeShortcode("ButtonLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProjectHeading title='A Cross-Play Future by Electronic Arts' abstract='Enabling millions of players to play with anyone on any gaming console and device' borderBottom huge mdxType="ProjectHeading" />
    <ProjectSection mdxType="ProjectSection">
  <ProjectInfo title='Timeline' abstract='Launched July 2022' mdxType="ProjectInfo" />
  <ProjectInfo title='Company' abstract='Electronic Arts' mdxType="ProjectInfo" />
  <ProjectInfo title='Role' abstract='Product designer' mdxType="ProjectInfo" />
  <ProjectInfo title='About' abstract='The EA crossplay design system provides a safe and accessible environment for players that scales across every device and gaming console.' mdxType="ProjectInfo" />
    </ProjectSection>
    <ButtonLink to="https://www.ea.com/en-gb/games/fifa/fifa-23/news/pitch-notes-fifa-23-cross-play-deep-dive" mdxType="ButtonLink">Read more about EA Cross-Play</ButtonLink>
    <h2>{`Context`}</h2>
    <p>{`There’s always been a looming problem in gaming - players on one console can’t play with those on another.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "25.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIklEQVQY00WQUUsCURSE/Xu9FgS+RFARYhpESu7mqmtroYHhQ9lLxi4sFBEY9B4EQoSBaEIS/YqI1r33fnGXdRs4MOecYRgmpZRCQ0qJkhIRhhEXUhKKECEEqPgnBFquR3OtkUpFeg3tlVos75MJBcOg2W7T7/c5PWlyXHfwfZ/aUYNiycRzXcoVE6N8gOt6OHaFXHab2WyWhEpFCYDnwYDsyjLX9Tqdsw4Za49Cy6JmO5g7azSKGUqmgVNqs7tuUa0esrp5wFI6z/D1JfLQXknC4XTKk23zaVk83j+wf9si3Sviuj7TXp7RxRaXV11u7DfunBGe12WjMyZ3PuTrY/yfUMWlqPgg5/PkGYTzqBcZ/CB/v1FKEgYy7kwRhCrhC58/RrNQgc4nMSEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Players on one console can’t play with those on another.",
          "title": "Players on one console can’t play with those on another.",
          "src": "/static/7a793accfeb33247df509639e22c7ee3/7dc98/ea-player-silos.png",
          "srcSet": ["/static/7a793accfeb33247df509639e22c7ee3/fabc2/ea-player-silos.png 300w", "/static/7a793accfeb33247df509639e22c7ee3/dadad/ea-player-silos.png 600w", "/static/7a793accfeb33247df509639e22c7ee3/7dc98/ea-player-silos.png 1200w", "/static/7a793accfeb33247df509639e22c7ee3/47614/ea-player-silos.png 1688w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Players on one console can’t play with those on another.`}</figcaption>{`
  `}</figure>
    <p>{`Although players have accepted this reality to be the norm, my team and I have worked to break down these silos and bring players together in a way not previously possible enabling a new technology called cross-play.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "25.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJklEQVQY02WQTU/CUBBF+bdu3bhy4UJMjCujIdYocSGxLCAWgRLwKyr8AEkkhjStIm6gUkRDX8t77xgq6sJZ3Zm5OZm5Ka0185JSgtYoKRFCIJVEaZVoEo8mFGHiRYMQIUrrZKWU/B5rTeoHOBwMOLNtrptNXNeldXPHVeMCz/Ow6+dY5Sq9Xo+iVaBSK9Hv96lVSxzsZwiC4A+olEqawWiEZRi08iZvQ5/CrY15aTEKJpjGFpXcHsH7mNPjBrlMmel0wvrOCZtGkWDkJ4w56/fCF9+nm83S20jjdLqkG4csFdM8dLq8FFZ5NldoP7apbTvUd10c557loyfW8h7j4ev/l+f0eY4qiojjONFittDiExl+EMURsZBIqZjNYkSsFtkr9AL4BWx7alZuBMx0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "With the cross-play services I co-designed, our players are able to play with any other player in the EA network.",
          "title": "With the cross-play services I co-designed, our players are able to play with any other player in the EA network.",
          "src": "/static/1373000593885996da3a3e4211ee3b2d/7dc98/ea-players-with-crossplay.png",
          "srcSet": ["/static/1373000593885996da3a3e4211ee3b2d/fabc2/ea-players-with-crossplay.png 300w", "/static/1373000593885996da3a3e4211ee3b2d/dadad/ea-players-with-crossplay.png 600w", "/static/1373000593885996da3a3e4211ee3b2d/7dc98/ea-players-with-crossplay.png 1200w", "/static/1373000593885996da3a3e4211ee3b2d/47614/ea-players-with-crossplay.png 1688w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`With the cross-play services I co-designed, our players are able to play with any other player in the EA network.`}</figcaption>{`
  `}</figure>
    <p>{`To create these cross-play services, I co-designed and continue to own all social coordination features required for players to play safely and fairly across platforms.`}</p>
    <h2>{`Impact`}</h2>
    <p>{`Currently, blockbuster games like `}<a parentName="p" {...{
        "href": "https://www.ea.com/games/apex-legends/news/ea-play-announcements"
      }}>{`Apex Legends`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.ea.com/games/starwars/squadrons/tips-and-tricks/how-to-play-with-friends-in-star-wars-squadrons"
      }}>{`Star Wars: Squadrons`}</a>{` have already adopted crossplay functionality, which is defined by the design system I helped create. As a result, players of both franchises can now play with any other player across consoles and devices.`}</p>
    <h2>{`Process`}</h2>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "18.666666666666668%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZklEQVQY05WPOw4AIQhEuf9FNf4KP41K2IwJxGIbiwczAwXQ3lteWGv9asDMQiEEUXLO4r0/upQiMcYDPLpzzuYpJZsjA8iotSY3vXeptZqHRqZ+jGF7d449XEwoc85j9AXVr+DlD6tgNaOhQ8ijAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": " ",
          "title": " ",
          "src": "/static/1edc3cceb0435665bac4c62c34948de5/4552c/ea-process.png",
          "srcSet": ["/static/1edc3cceb0435665bac4c62c34948de5/fabc2/ea-process.png 300w", "/static/1edc3cceb0435665bac4c62c34948de5/dadad/ea-process.png 600w", "/static/1edc3cceb0435665bac4c62c34948de5/4552c/ea-process.png 960w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{` `}</figcaption>{`
  `}</figure>
    <hr></hr>
    <p>{`This current project is under an NDA. However, I would be glad to discuss my experience and learnings at Electronic Arts over a Zoom call.`}</p>
    <ButtonLink to="mailto:kristy.leung11@gmail.com?subject=Schedule%20a%20chat" mdxType="ButtonLink">Schedule a chat 👋🏽</ButtonLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      